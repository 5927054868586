import "./gridcontainer.css";
import "./paragraph.css";
import "./ChatBot.css";
import ChatBot from "./ChatBot.js";
import "./coursetile.css";
import "material-symbols";
import { useNavigate, Link } from "react-router-dom";
import { useParamContext } from "./ParamContext";
function StudentPage() {
  const navigate = useNavigate();
  const { setParam } = useParamContext();
  const handleButtonClick = (buttonId) => {
    setParam("Hello");
    navigate("/Course");
  };
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <Link to="#headerid" class="logo">
          Maverick Brilliance
        </Link>
        <nav class="nav-items">
          <Link to="/Profile">Profile</Link>
          <Link to="/ManageCourses">Manage Courses</Link>
          <Link to="/">Sign Out</Link>
        </nav>
      </header>
      <br />
      <p className="p-pagetitle" style={{ textAlign: "left" }}>
        Dashboard
      </p>
      <hr width="67%" />
      <div className="grid-container-studentpage">
        <div>
          <div
            type="button"
            id="MB-CSE-0001"
            className="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div className="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "0.5%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0001
                  <br />
                  Introduction To Programming
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
        <div>
          <div
            type="button"
            id="MB-CSE-0002"
            class="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div class="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "0.5%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0002
                  <br />
                  Datastructures And Algorithms
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
        <div>
          <div
            type="button"
            id="MB-CSE-0003"
            class="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div className="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "3%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0003
                  <br />
                  Data Management
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
        <div>
          <button class="chatbot-toggler">
            <span class="material-symbols-outlined">mode_comment</span>
            <span class="material-symbols-outlined">close</span>
          </button>
          <div class="chatbot">
            <header>
              <h2>Chatbot</h2>
            </header>
            <ul class="chatbox">
              <li class="chat incoming">
                <span class="material-symbols-outlined">smart_toy</span>
                <p>
                  Hi there 👋. <br /> How Can I help You Today?
                </p>
              </li>
            </ul>
            <div class="chat-input">
              <textarea placeholder="Enter a Message...." required></textarea>
              <span id="send-btn" class="material-symbols-outlined">
                send
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="p-pagetitle">Announcements</p>
      <hr width="67%" />
      <br />
      <div className="p1" style={{ textAlign: "left" }}>
        No Announcements Added Yet
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default StudentPage;
