import Header from "./Header.js";
import Footer from "./Footer.js";
import "./heading.css";
import "./paragraph.css";
import "./gridcontainer.css";
import "./button.css";
import "./image.css";

function ForgotPassword() {
  return (
    <div className="page-container">
      <Header />
      <div className="grid-container-forgotpassword">
        <div>
          <img
            className="img-forgotpassword"
            src="https://ngopulse.net/themes/jobcy/images/auth/reset-password.png"
          />
        </div>
        <div>
          <h1 style={{ marginTop: "10%", marginLeft: "25%" }}>
            Forgot Password ?
          </h1>
          <br />
          <p style={{ marginLeft: "25%" }}>
            No biggie. We have your back. Please enter your Email Address and
            Student Id below and click on "Send Reset Link". We will send you a
            "Password Reset" link to your mail id.
          </p>
          <br />
          <br />
          <br />
          <form className="container">
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              required
            />
            <input
              type="text"
              placeholder="Student Id"
              name="studentid"
              required
            />
            <button type="submit" className="btn-login">
              Send Reset Link
            </button>
          </form>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ForgotPassword;
