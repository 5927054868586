import { Link } from "react-router-dom";
function Availability() {
  return (
    <div>
      <header className="header" id="headerid">
        <Link href="#headerid" className="logo">
          Maverick Brilliance
        </Link>
        <nav className="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", textAlign: "left", fontWeight: "bold" }}>
        <br />
        COURSE AVAILABILITY
      </p>
      <br />
      <hr width="100%" />
      <div className="grid-container-availability">
        <div>
          <div className="index-items"></div>
        </div>
        <div>
          <div id="courseobjectives">
            <p
              style={{
                fontSize: "x-large",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Course Availability
            </p>
            <p
              style={{
                textAlign: "left",
              }}
            >
              {" "}
              Course not available right now.
            </p>{" "}
            <br />
            <hr />
          </div>
        </div>
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Availability;
