import "./gridcontainer.css";
import "./paragraph.css";
import "./ChatBot.css";
import "./coursetile.css";
import { useNavigate, Link } from "react-router-dom";
import ChatBot from "./ChatBot.js";
import { useParamContext } from "./ParamContext";
function Instructor() {
  const navigate = useNavigate();
  const handleButtonClick = (buttonId) => {
    navigate("/CourseI");
  };
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <Link to="#headerid" class="logo">
          Maverick Brilliance
        </Link>
        <nav class="nav-items">
          <Link to="/Profile">Profile</Link>
          <Link to="/ManageCourses">Manage Courses</Link>
          <Link to="/">Sign Out</Link>
        </nav>
      </header>
      <br />
      <p className="p-pagetitle" style={{ textAlign: "left" }}>
        Dashboard
      </p>
      <hr width="67%" />
      <div className="grid-container-studentpage">
        <div>
          <div
            type="button"
            id="MB-CSE-0001"
            className="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div className="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "0.5%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0001
                  <br />
                  Introduction To Programming
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
        <div>
          <div
            type="button"
            id="MB-CSE-0002"
            class="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div class="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "0.5%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0002
                  <br />
                  Datastructures And Algorithms
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
        <div>
          <div
            type="button"
            id="MB-CSE-0003"
            class="coursetile-p"
            onClick={() => handleButtonClick("example")}
          >
            <header>
              <div className="logo">MAVERICK BRILLIANCE</div>
            </header>
            <div style={{ marginLeft: "3%", fontSize: "large" }}>
              <br />
              <br />
              <b>
                <u>
                  MB-CSE-0003
                  <br />
                  Data Management
                  <br />
                </u>
                2023 - Fall
              </b>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Instructor;
