import "./managecourses.css";
import { Link } from "react-router-dom";
function ManageCourses() {
  return (
    <div className="page-container">
      <header className="header" id="headerid">
        <Link to="#headerid" className="logo">
          Maverick Brilliance
        </Link>
        <nav className="nav-items">
          <Link to="/Profile">Profile</Link>
          <Link to="/StudentPage">Dashboard</Link>
          <Link to="/">Sign Out</Link>
        </nav>
      </header>
      <h2>Course Management - Enroll or Drop from a Course at One Place</h2>
      <div className="class-search">
        <p>Enter Course Code to check course availability.</p>
        <input
          style={{ width: "25%" }}
          className="coursecode"
          type="text"
          placeholder="Enter Course Code"
          name="coursecode"
          required
        />
        <br />
        <button
          className="coursecode1"
          onClick={() => window.alert("You Cannot Enroll Now")}
          type="submit"
        >
          Enroll
        </button>
      </div>
      <br />
      <hr />
      <div className="grid-container-managecourses">
        <div>
          <div className="p1">
            <h2> All Courses</h2>
          </div>
          <table className="tablecss">
            <tr>
              <th>Course Name</th>
              <th>Course Code</th>
              <th>Professor</th>
              <th>Availability</th>
            </tr>
            <tr>
              <td>Introduction To Programming</td>
              <td>MB-CSE-0001</td>
              <td>Prof. Eleanor Bennett</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
            <tr>
              <td>Data Structures and Algorithms</td>
              <td>MB-CSE-0002</td>
              <td>Prof. Oliver Anderson</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
            <tr>
              <td>Database Management</td>
              <td>MB-CSE-0003</td>
              <td>Prof. Isabella Ramirez</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
            <tr>
              <td>Artificial Intelligence</td>
              <td>MB-CSE-0004</td>
              <td>Prof. Alexander Mitchell</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
            <tr>
              <td>Web Development</td>
              <td>MB-CSE-0005</td>
              <td>Prof. Sophia Foster</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
            <tr>
              <td>Software Engineering</td>
              <td>MB-CSE-0006</td>
              <td>Prof. William Turner</td>
              <td>
                <Link to="/Availability">Check Availability</Link>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div className="p1">
            <h2>Enrolled Courses</h2>
          </div>
          <br />
          <br />
          <table className="tablecss">
            <tr>
              <th>Course Name</th>
              <th>Course Code</th>
              <th>Professor</th>
              <th>Availability</th>
            </tr>
            <tr>
              <td>Introduction To Programming</td>
              <td>MB-CSE-0001</td>
              <td>Prof. Eleanor Bennett</td>
              <td>
                <Link
                  to="#"
                  onClick={() => window.alert("You Cannot Drop The Course")}
                >
                  Drop
                </Link>
              </td>
            </tr>
            <tr>
              <td>Data Structures and Algorithms</td>
              <td>MB-CSE-0002</td>
              <td>Prof. Oliver Anderson</td>
              <td>
                <Link
                  to="#"
                  onClick={() => window.alert("You Cannot Drop The Course")}
                >
                  Drop
                </Link>
              </td>
            </tr>
            <tr>
              <td>Database Management</td>
              <td>MB-CSE-0003</td>
              <td>Prof. Isabella Ramirez</td>
              <td>
                <Link
                  to="#"
                  onClick={() => window.alert("You Cannot Drop The Course")}
                >
                  Drop
                </Link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}
export default ManageCourses;
