import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./gridcontainer.css";
import "./Header.css";
function QaOfficerAdmin() {
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <a href="#headerid" class="logo">
          Maverick Brilliance
        </a>
        <nav class="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", fontWeight: "bold" }}>
        QA OFFICER MANAGEMENT
      </p>
      <hr width="100%" />
      <div class="grid-container-student-admin">
        <div>
          <div class="index-items">
            <ul>
              <li>
                <HashLink to="/QaOfficerAdmin#manageofficers">
                  Manage QA Officer
                </HashLink>
              </li>
              <li>
                <HashLink to="/QaOfficerAdmin#courseperformance">
                  Course Performance
                </HashLink>
              </li>
              <li>
                <HashLink to="/QaOfficerAdmin#feedback">Feedback</HashLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div id="manageofficers" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>QA Officer Management</p>
            Enter QA Officer Id and click on search button <br />
            <br />
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>QA Officer details will be shown in this space</p>
            <hr />
          </div>
          <div id="courseperformance" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>Course Performance</p>
            Enter QA Officer and click on search button <br />
            <br />
            <label for="idno1"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno1"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Course Performance can be viewed here</p>
            <hr />
          </div>
          <div id="feedback" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>Feedback</p>
            Enter QA Officer and click on search button <br />
            <br />
            <label for="idno2"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno2"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>QA Officer feedback can be viewed in this space</p>
            <h />
          </div>
        </div>
      </div>
      <footer class="footer" id="footer">
        <div class="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default QaOfficerAdmin;
