import "./ForgotPassword.js";
import React, {useState , useEffect} from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "./heading.css";
import "./paragraph.css";
import "./gridcontainer.css";
import "./button.css";
import "./image.css";
import "./formfeild.css";
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
function Login() {
  const initialState = {email:'',password:'',forgotPassword:false};
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || "{}");
  const navigate = useNavigate();
  const [state,setState] = useState({});
  const [credentials, setCredentials] = useState(initialState);
  function onSignin(event) {
    event.preventDefault();
    axios({
        method: 'post',
        url: 'http://localhost:80/api/' + '/login.php',
        headers: {
            'content-type': 'application/json'
        },
        data: credentials
    })
        .then(result => {
            setState({
                dataSent: result.data.sent,
            });
            document.getElementById('invalid-cred').classList.add('d-none');
            setCredentials(initialState);
            localStorage.setItem("userInfo", JSON.stringify(result.data));
            window.location = `/${result.data.User_Type}`
        })
        .catch(error => {
            setState({
                error: error.message
            });
            setCredentials(initialState);
            document.getElementById('invalid-cred').classList.remove('d-none');
            console.log(state['error']);
        });
  }
  return (
    <div className="page-container">
      <Header />
      <div>
        <div className="grid-container-login">
          <div>
            <img
              className="img-login"
              src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRoIWR4eEZEeNTC4z1PhTpx7XRTDUwH3SjsHMsU8PGFH-Rlp2oa"
            />
          </div>
          <div className="info" id="info">
            <form className="container" name="loginform" onSubmit={onSignin}>
              <h1 className="h-homepage" align="center">
                Login
              </h1>
              <label for="email">
                <b>Email</b>
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                id="email"
                required
              />

              <label for="psw">
                <b>Password</b>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                name="psw"
                id="psw"
                required
              />
              <button
                style={{ width: "100%" }}
                type="submit"
                className="btn-login"
                id="loginbtn"
              >
                Login
              </button>
              <p id="login-message"></p>
            </form>
            <br />
            <h3 style={{ textAlign: "center", marginLeft: "32%" }}>
              New User? Register Your Account
            </h3>
            <div style={{ textAlign: "center", marginLeft: "32%" }}>
              <Link to="/ForgotPassword">Forgot Password ?</Link>
            </div>
            <div align="center">
              <Link to="/StudentPage">Student</Link>
              <br />
              <Link to="/Instructor">Instructor</Link>
              <br />
              <Link to="/Admin">Admin</Link>
              <br />
              <Link to="/Coordinator">Co-Ordinator</Link>
              <br />
              <Link to="/Qaofficer">QA Officer</Link>
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
