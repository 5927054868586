import Header from "./Header.js";
import Footer from "./Footer.js";
import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import axios from 'axios';

import "./heading.css";
import "./paragraph.css";
import "./gridcontainer.css";
import "./button.css";
import "./image.css";
import "./formfeild.css";
function SignUp() {
  const initialState = { studentid: '', firstName: '', email: '', phone: '', password: '' , userType:'Student' };
    const [state, setState] = useState({});
    const [registrationInfo, setRegistrationInfo] = useState(initialState);
    function handleChange(event) {
      const { name, value } = event.target;
      setRegistrationInfo({ ...registrationInfo, [name]: value })
   }

    function onSubmit(event) {
      event.preventDefault();
      debugger;
      axios({
          method: 'post',
          url: 'http://localhost:80/api/' + 'register.php',
          headers: {
              'content-type': 'application/json'
          },
          data: registrationInfo
      })
          .then(result => {
              console.log(result.data)
              setState({
                  dataSent: result.data.sent,
              });
              setRegistrationInfo(initialState);
              var x = document.getElementById("snackbar");
              x.className = "show";
              x.innerText = 'Registration Successful';
              setTimeout(function(){
                   x.className = x.className.replace("show", "");
          }, 3000);
          })
          .catch(error => {
              console.log(error.message)
              debugger;
              setState({
                  error: error.message
              });
              var x = document.getElementById("snackbar");
              x.className = "show danger";
              x.innerText = 'Email Address already present';
              setTimeout(function(){
                   x.className = x.className.replace("show", "");
          }, 5000);
              console.log(state['error']);
          });
  }
  return (
    <div className="page-container">
      <Header />
      <div className="grid-container-forgotpassword">
        <div>
          <img
            className="img-signup"
            style={{ marginTop: "30%", height: "50%", width: "75%" }}
            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSol3WnWppNI_6vqGJAHEBHYaXiH7waMhI3fumWpam5iVPQzcMD"
            alt="alt"
          />
        </div>
        <div className="info" id="info">
          <form className="container" method="post" name="signupform" onSubmit={onSubmit}>
            <h1 align="center">Sign Up</h1>
            <label for="firstName">
              <b>Name</b>
            </label>
            <input type="text" placeholder="Enter Name" name="firstName" onChange={handleChange} required />
            <label for="studentid">
              <b>ID Number</b>
            </label>
            <input type="text" placeholder="ID" name="studentid" onChange={handleChange} required />

            <label for="email">
              <b>Email</b>
            </label>
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              onChange={handleChange}
              required
            />

            <label for="phone">
              <b>Contact</b>
            </label>
            <input
              type="text"
              placeholder="Enter Contact Number"
              name="phone"
              onChange={handleChange}
              required
            />

            <label for="password">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handleChange}
              required
            />

            <button
              type="submit"
              onclick="window.alert('User Registered')"
              className="btn-signup"
            >
              Sign Up
            </button>
            <br />
            <br />
            <h3 align="center">
              Already Have An Account? Login to Your Account
            </h3>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
