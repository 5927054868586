import "./Header.css";
import "./Footer.css";
import "./image.css";
import "./gridcontainer.css";
import "./formfeild.css";
import "./button.css";

import { useNavigate, Link } from "react-router-dom";
function Profile() {
  const navigate = useNavigate();
  return (
    <div className="page-container">
      <header className="header" id="headerid">
        <a href="#headerid" class="logo">
          Maverick Brilliance
        </a>
        <nav className="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <div>
        <div className="grid-container-profile">
          <div>
            <img
              className="img-profile"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhyhj1gUUYu1c8817GfPwApJbYzW9lJdjSXQ&usqp=CAU"
            />
          </div>
          <div>
            <form className="container" style={{ fontSize: "x-large" }}>
              <label for="lastname">
                <b>Last Name</b>
              </label>
              <p>Kondapaneni</p>

              <label for="firstname">
                <b>First Name</b>
              </label>
              <p>Charan Sai</p>

              <label for="contact">
                <b>Contact</b>
              </label>
              <p>682-270-2079</p>

              <label for="biography">
                <b>Biography</b>
              </label>
              <p>No Biography added</p>

              <label for="links">
                <b>Links</b>
              </label>
              <p>No Links added</p>
            </form>
          </div>
          <div>
            <button
              type="button"
              className="btn-profile"
              onClick={() => navigate("/EditProfile")}
            >
              Edit Profile
            </button>
          </div>
        </div>
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Profile;
